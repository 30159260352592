<template>
  <v-card class="mt-4 mx-auto">
    <v-overlay :value="policyTableOverlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="success"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-card
        dark
        color="color_green"
      >
        <v-card-title> {{ title }} </v-card-title>
      </v-card>
    </v-sheet>
    <v-card-text class="pt-0">
      <v-data-table
        id="policy-table"
        :headers="policy.headers"
        :items="policy.policies"
        :search="search"
        :loading="policyTableLoading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              class="mb-2"
              @click="newPolicyButtonEvent"
            >
              New policy
            </v-btn>
          </v-toolbar>
        </template>
        <template
          v-slot:[`item.policy_description`]="{ item }"
          class="d-grid"
        >
          <span
            class="d-inline-block text-truncate"
            style="max-width: 30em"
          >
            {{ item.policy_description }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editPolicyButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="title.includes('APP')"
            small
            :disabled="item.service_type === 'ALERT EX-POST' || item.service_type === 'REPORTS'"
            @click="configurationPolicyButtonEvent(item)"
          >
            mdi-cog
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deletePolicyEvent(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    title: {
      type: String,
      default: 'POLICY'
    }
  },
  data() {
    return {
      search: '',
      policyTableLoading: false,
      policyTableOverlay: false
    };
  },

  computed: {
    ...mapState(['policy'])
  },

  async created() {
    this.policyTableLoading = true;

    if (this.title.includes('API')) {
      await this.storePolicies();
    } else {
      await this.storeAppPolicies();
    }

    this.policyTableLoading = false;
  },

  methods: {
    ...mapActions([
      'storePolicies',
      'deleteTablePolicy',
      'storeCustomerCRMID',
      'storeIsModifingPolicy',
      'storeAppPolicies',
      'deleteAppPolicyAction'
    ]),
    newPolicyButtonEvent() {
      this.$emit('newPolicyEvent');
      this.storeIsModifingPolicy(false);
    },
    async editPolicyButtonEvent(item) {
      this.$emit('editPolicyEvent', item);
      await this.storeIsModifingPolicy(true);
    },
    async deletePolicyEvent(item) {
      if (this.title.includes('API')) {
        this.policyTableOverlay = true;
        await this.deleteTablePolicy(item.policy_id);
        this.policyTableOverlay = false;
      } else {
        this.policyTableOverlay = true;
        await this.deleteAppPolicyAction({
          service_id: item.service_id,
          policy_id: item.policy_id,
          pws_id: item.pws_id || ''
        });
        this.policyTableOverlay = false;
      }
    },
    configurationPolicyButtonEvent(item) {
      this.policyTableOverlay = true;
      this.$emit('configurationPolicyEvent', item);
      this.policyTableOverlay = false;
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
